import type { ApiResponse, LibraryItem, PaginatedResponse } from '~/types'

export type ReleaseComponent = {
  type_id: number | null
  major: string
  minor: string
  patch: string
}

export type ReleaseForm = {
  id?: number
  name: string
  released_at: string
  time: string
  link: string
  note?: string
  components: ReleaseComponent[]
}

export type ReleaseDTO = Omit<ReleaseForm, 'time' | 'components'> & {
  components: {
    type_id: number
    version: string
  }[]
}

export type ReleaseResponse = Omit<ReleaseDTO, 'components'> & {
  id: number
  components: {
    id: number
    status: LibraryItem
    type: LibraryItem
    version: string
  }[]
}

export const useCreateRelease = async (releaseDTO: ReleaseDTO) => {
  const { data, error } = await useAPIFetch<ApiResponse<ReleaseResponse>>(`/releases`, {
    method: 'POST',
    body: releaseDTO,
  })

  if (error.value) throw createError(error.value)

  return data.value
}

export const useUpdateRelease = async (releaseDTO: ReleaseDTO) => {
  const { data, error } = await useAPIFetch<ApiResponse<ReleaseResponse>>(`/releases/${releaseDTO.id}`, {
    method: 'PUT',
    body: releaseDTO,
  })

  if (error.value) throw createError(error.value)

  return data.value
}

export const useDeleteRelease = async (releaseId: ReleaseResponse['id']) => {
  const { data, error } = await useAPIFetch<ApiResponse<{}>>(`/releases/${releaseId}`, {
    method: 'DELETE',
  })

  if (error.value) throw createError(error.value)

  return data.value
}

export const useReleaseList = async (query?: Record<string, any>) => {
  const { data, error } = await useAPIFetch<PaginatedResponse<ReleaseResponse[]>>(`/releases`, {
    method: 'GET',
    query,
  })

  if (error.value) throw createError(error.value)

  return data.value
}
